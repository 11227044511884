import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { fetchTrendsById, fetchTrendsChartFilterData } from './TrendsSlice';
import { TrendsDetailsProps } from './TrendsDetails';
import { defaultStaticRanges, defineds, formateDate } from '../../../common/DateRangeSelector/DefaultRanges';
import calenderIcon from '../../../../images/calendar-icon.svg';
import filterIcon from '../../../../images/filter-icon.svg';
import downIcon from '../../../../images/chevron-down.svg';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { useDetectClickOutside } from 'react-detect-click-outside';
import DateRangeSelector from '../../../common/DateRangeSelector/DateRangeSelector';
import LineChart from './LineChart';
import noDataJson from './noData.json';
import './trends.scss';
import Loader from '../../../common/page-loader/ComponentLoader';
import { AppUser } from '../../../user/model/AppUser';
import axios from 'axios';
import { clearAssetEventSchedule, fetchAssetListById } from '../../../dashboard/DashboardSlice';
import { updateAssetControlScanStatus } from '../../../asset/AssetControlSlice';
import { showToaster } from '../../../dashboard/components/asset-location/AssetList';
import { ConvertTimeToLocal } from '../../../../utilities/CommonFunctions';

interface CardsDesignProps {
  trend: TrendsDetailsProps;
  index: number;
  selectedDate: {
    startDate: Date | null;
    endDate: Date | null;
  };
  loadingIcon: boolean;
  trendsMinMaxValue: {
    [key: string]: {
      minValue: number;
      mean: number;
      maxValue: number;
    };
  };
  setTrendsMinMaxValue: React.Dispatch<React.SetStateAction<{
    [key: string]: {
      minValue: number;
      mean: number;
      maxValue: number;
    };
  }>>;
}

const CardsDesign: React.FC<CardsDesignProps> = ({ trend, index, selectedDate, loadingIcon, trendsMinMaxValue, setTrendsMinMaxValue }) => {
  const trendMinMax = trendsMinMaxValue?.[trend?.trendName];
  const unitOfMeasure = trend?.short_UnitOfMeasure
    ? trend?.short_UnitOfMeasure
    : trend?.unitOfMeasure === 'None'
      ? ''
      : trend?.unitOfMeasure;
  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='title'>{trend?.trendName}</div>
        </div>

        <div className='card-body'>
          <div className='left-container'>
            <h1 className='psi'>
              {trend?.value} {unitOfMeasure}
            </h1>
            <div className='cont'>{trend?.date ? ConvertTimeToLocal(trend?.date) : '00/00/0000 00:00:00'}</div>
            <div>
              &nbsp;Min: {`${trendMinMax?.minValue || 0} ${unitOfMeasure ?? ''}`}&nbsp; |&nbsp; Mean:{' '}
              {`${trendMinMax?.mean || 0} ${unitOfMeasure ?? ''}`} &nbsp;|&nbsp; Max: {`${trendMinMax?.maxValue || 0} ${unitOfMeasure ?? ''}`}
              &nbsp;
            </div>

            <div className='thresholdcontainer margin-top'>
              <span className='threshold'>↓ {trend?.maxThreshold}% </span>Max threshold{' '}
            </div>
            <div className='thresholdcontainer'>
              <span className='threshold'>↑ {trend?.minThreshold}% </span>Min threshold
            </div>
          </div>
          <div className='rigth-container trends-right'>
            <LineChart
              chartName={trend?.trendName}
              unitOfMeasure={unitOfMeasure}
              chartData={trend?.dataPoints}
              index={index}
              selectedDate={selectedDate}
              loadingIcon={loadingIcon}
              setTrendsMinMaxValue={setTrendsMinMaxValue}
              key={trend?.trendName}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Trends = () => {
  const dispatch = useAppDispatch();
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const { trends, trendsFilterData, loading } = useAppSelector((state) => state.trends);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: startOfDay(addDays(new Date(), -6)),
    endDate: endOfDay(new Date()),
    key: 'selection',
  });
  const [trendsData, setTrendsData] = useState<TrendsDetailsProps[] | undefined>([]);
  const [filteredCheckedValues, setFilteredCheckedValues] = useState<string[]>([]);
  const [filteredVal, setFilteredVal] = useState<string[]>([]);
  const [prevFilterVal, setPrevFilterVal] = useState<string[]>([]);
  const [filterLength, setFilterLength] = useState<number>(0);
  const [noTrendsData, setNoTrendsData] = useState(noDataJson);
  const assetControlData = useAppSelector((state) => state?.assetControl);
  const [loadingIcon, setLoadingIcon] = useState<boolean>(true);
  const [trendsMinMaxValue, setTrendsMinMaxValue] = useState<{
    [key: string]: {
      minValue: number;
      mean: number;
      maxValue: number;
    };
  }>({});


  useEffect(() => {
    if (!trendsFilterData) {
      dispatch(fetchTrendsChartFilterData())
        .unwrap()
        .then((response: any) => {
          const filterSelectedData = response?.map((item: any) => item.name);
          setFilteredVal(filterSelectedData);
          setFilteredCheckedValues(filterSelectedData);
          setPrevFilterVal(filterSelectedData);
          setFilterLength(filterSelectedData?.length);
        })
        .catch((error) => {
          setFilteredCheckedValues([]);
          setPrevFilterVal([]);
          console.error('Failed to fetch Filter data:', error);
        });
    } else {
      const filterSelectedData = trendsFilterData?.map((item: any) => item.name);
      setFilteredVal(filterSelectedData);
      setFilteredCheckedValues(filterSelectedData);
      setPrevFilterVal(filterSelectedData);
      setFilterLength(filterSelectedData?.length);
    }
  }, []);

  //to reload the data, when the scan is completed
  useEffect(() => {
    if (assetControlData?.assetControlScanStatus) {
      setLoadingIcon(true);
      const wellName = selectedAsset?.selectedAssetName ?? '';
      const startDate = formateDate(selectedDateRange.startDate);
      const endDate = formateDate(selectedDateRange.endDate);

      if (wellName === '') return;
      dispatch(fetchTrendsById({ wellName: wellName, startDate: startDate, endDate: endDate }))
        .unwrap()
        .then((response: TrendsDetailsProps[]) => {
          const currentFilterValues = trendsData?.map((trend) => trend.trendName) || [];
          const updatedTrendsData = response?.filter((trend) => currentFilterValues?.includes(trend?.trendName));
          setTrendsData(updatedTrendsData);
          setLoadingIcon(false);
        })
        .catch((error) => {
          setTrendsData([]);
          console.error('Failed to fetch Trends tab details:', error);
          setLoadingIcon(false);
        });
      dispatch(updateAssetControlScanStatus(false));
    }
  }, [assetControlData?.assetControlScanStatus]);


  useEffect(() => {
    setLoadingIcon(true);
    const wellName = selectedAsset?.selectedAssetName ?? '';
    const startDate = formateDate(selectedDateRange.startDate);
    const endDate = formateDate(selectedDateRange.endDate);

    if (wellName === '') return;
    dispatch(fetchTrendsById({ wellName: wellName, startDate: startDate, endDate: endDate }))
      .unwrap()
      .then((response: TrendsDetailsProps[]) => {
        setTrendsData(response);
        setLoadingIcon(false);
      })
      .catch((error) => {
        setTrendsData([]);
        console.error('Failed to fetch Trends tab details:', error);
        setLoadingIcon(false);
      });
  }, [selectedDateRange, selectedAsset?.selectedAssetName]);

  const loggedInUser = useAppSelector((state) => state?.user?.loggedInUser);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  const initialUserState = loggedInUser || storedUser || undefined;
  const cancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);

  useEffect(() => {
    const selectedAssetName = selectedAsset?.selectedAssetName ?? '';
    const groupName = selectedAsset?.selectedGroup ?? '';

    if (initialUserState?.id && groupName) {
      cancelTokenSource.current = axios.CancelToken.source();
      dispatch(clearAssetEventSchedule());
      setTimeout(() => {
        dispatch(
          fetchAssetListById({
            userId: initialUserState.id,
            groupName,
            startIndex: 0,
            endIndex: 1,
            searchQuery: selectedAssetName ? '?filter=' + selectedAssetName : '',
            cancelToken: cancelTokenSource?.current?.token,
          }),
        );
      });
    }

    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel('Component unmounted or route changed.');
      }
    };
  }, [dispatch, selectedAsset, initialUserState?.id]);

  const handleSlideOutClick = (e: any) => {
    if (e.target.id === 'range-calendar-input') return;
    if (showCalendar) {
      setShowCalendar(!showCalendar);
    }
  };

  const isFilterChanged = prevFilterVal?.some((item) => !filteredCheckedValues?.includes(item)) || filteredCheckedValues?.some((item) => !prevFilterVal?.includes(item));

  const handleFilterOutClick = (e: any) => {
    if (e?.target?.id === 'delete-btn') return;
    if (showFilterPopup) {
      setShowFilterPopup(false);
      isFilterChanged && setFilteredCheckedValues([...prevFilterVal])
    }
  };

  const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });
  const filterRef = useDetectClickOutside({ onTriggered: handleFilterOutClick });

  const handleClearButton = () => {
    setFilteredCheckedValues([]);

  };
  const handleApplyButton = () => {
    if (filteredCheckedValues?.length === 0) {
      showToaster('Please select atleast one filter', 'error');
      return;
    }
    const updatedTrendsData = trends?.filter((trend) => filteredCheckedValues?.includes(trend?.trendName));

    setTrendsData(updatedTrendsData);
    setFilterLength(filteredCheckedValues.length);
    setShowFilterPopup(false);
    setPrevFilterVal(filteredCheckedValues);
    const noDataUpdated = noDataJson?.filter((trend) => filteredCheckedValues?.includes(trend?.trendName));
    setNoTrendsData(noDataUpdated);
  };

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const checkFilterAvl = filteredCheckedValues?.includes(value);
    if (checkFilterAvl) {
      const updatedFilteredCheckedValues = filteredCheckedValues?.filter((filteredValue) => filteredValue !== value);
      setFilteredCheckedValues(updatedFilteredCheckedValues);
    } else {
      setFilteredCheckedValues((prev) => [...prev, value]);
    }
  };

  const renderdateRange = () => {
    return (
      <div className='trends-container-date-range'>
        <div className='input-group'>
          <img src={calenderIcon} alt='calendar-icon' className='absolute top-3 left-3' />
          <input
            id='range-calendar-input'
            className='date-range-input'
            value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
            onClick={() => setShowCalendar(!showCalendar)}
          />
        </div>
        <div ref={ref}>
          {showCalendar && (
            <DateRangeSelector
              setShowCalendar={setShowCalendar}
              setSelectedDateRange={setSelectedDateRange}
              staticRanges={defaultStaticRanges}
              minDate={defineds?.startOfLastYear}
              maxDate={defineds?.endOfToday}
              selectedDateRange={selectedDateRange}
            />
          )}
        </div>

        <div style={{ position: 'relative', zIndex: '8' }}>
          <div
            id='filter-btn'
            style={{
              border: showFilterPopup
                ? '1px solid var(--Dark-mode-400, #4A5463)'
                : '1px solid var(--Dark-mode-400, #4A5463)',
              background: showFilterPopup ? 'var(--Dark-mode-800, #001023)' : 'inherit',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowFilterPopup(!showFilterPopup);
            }}
          >
            <img src={filterIcon} alt='filterIcon' />
            Filter {`(${filterLength})`}
            <img src={downIcon} className={!showFilterPopup ? '' : 'rotate-180'} alt='upIcon' />
          </div>

          {showFilterPopup && (
            <div className='trends-filter-modal-container' ref={filterRef}>
              <div className='trends-filter-modal-header'>Filter asset telemetry</div>

              <div className='trends-filter-modal-body'>
                {filteredVal?.map((trend) => {
                  return (
                    <>
                      <div className='checkbox-main-container'>
                        <label className='checkbox-container'>
                          <input
                            type='checkbox'
                            id='showCheckbox'
                            checked={filteredCheckedValues?.includes(trend)}
                            onChange={handleCheckbox}
                            value={trend}
                          />
                          <span className='checkbox-checkmark'></span>
                          <span className='inline-block pl-[29px]'>{trend}&nbsp;</span>
                        </label>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className='trends-filter-modal-footer'>
                <button className={'footer-btn'} onClick={handleClearButton}>
                  Clear
                </button>
                <button className={'footer-btn'} onClick={handleApplyButton}>
                  Apply
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const findTrendsData = (trendName: any) => {
    const data = trendsData?.find((item) => {
      return item?.trendName?.toLowerCase() === trendName?.toLowerCase();
    });
    return data;
  };

  return (
    <>
      <div
        className={
          showCalendar || showFilterPopup ? 'trends-container trends-calender-filter-open' : 'trends-container'
        }
        style={{ position: 'relative' }}
      >
        {renderdateRange()}
        {loading ? (
          <div className='trend-loader flex items-center justify-center w-100'>
            <Loader />
          </div>
        ) : (
          <>
            {noTrendsData.map((item, index) => {
              const isDataAvl = findTrendsData(item?.trendName);
              if (isDataAvl) {
                return (
                  <CardsDesign
                    key={`${isDataAvl?.date}-${index}`}
                    trend={isDataAvl}
                    index={index}
                    selectedDate={{ startDate: selectedDateRange.startDate, endDate: selectedDateRange.endDate }}
                    loadingIcon={loadingIcon}
                    trendsMinMaxValue={trendsMinMaxValue}
                    setTrendsMinMaxValue={setTrendsMinMaxValue}
                  />
                );
              }
              return (
                <CardsDesign
                  key={`${item?.date}-${index}`}
                  trend={item}
                  index={index}
                  selectedDate={{ startDate: selectedDateRange.startDate, endDate: selectedDateRange.endDate }}
                  loadingIcon={loadingIcon}
                  trendsMinMaxValue={trendsMinMaxValue}
                  setTrendsMinMaxValue={setTrendsMinMaxValue}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default Trends;
