import React, { useState, useRef, useEffect } from "react";
// import { DateRangePicker } from "react-date-range";
import { formateDate } from "./DefaultRanges";
import { addDays, endOfDay, format, startOfDay, subYears } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import "./datepicker.scss";

import PropTypes from "prop-types";
import { DateRangePicker } from 'react-date-range';
import { useAppSelector } from "../../../hooks/storeHooks";

interface DateRangeSelectorProps {
     isOpen?: boolean;
     ranges?: any;
     onChange?: any;
     onSubmit?: any;
     setShowCalendar?: any;
     rest?: any;
     setSelectedDateRange: any;
     staticRanges: any;
     minDate: any;
     maxDate: any;
     selectedDateRange: {
          startDate: Date;
          endDate: Date;
          key: string;
     };
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({ ranges, onChange, onSubmit, setShowCalendar, setSelectedDateRange, staticRanges, minDate, maxDate, selectedDateRange, ...rest }) => {
     const selectedAssetDetails = useAppSelector((state) => state.assetDetail.assetDetail?.assetDetails);
     const [calendar, setCalendar] = useState({
          startDate: selectedDateRange?.startDate || startOfDay(addDays(new Date(), -6)),
          endDate: selectedDateRange?.endDate || endOfDay(new Date()),
          key: selectedDateRange?.key || "selection"
     })
     const dateRef = useRef<HTMLDivElement>(null);
     const [updatedRanges, setUpdatedRanges] = useState<any>(staticRanges);
     const [customMinDate, setCustomMinDate] = useState<any>(minDate);
     function formatDateDisplay(date: any, defaultText?: any) {
          if (!date) return defaultText;
          return format(date, "MM/DD/YYYY");
     }

     const handleSelect = (ranges: any) => {
          setCalendar(ranges.selection);
     };

     const handleApply = () => {
          setSelectedDateRange({
               startDate: calendar.startDate,
               endDate: calendar.endDate,
               key: calendar.key
          });
          setShowCalendar(false);
     };

     const handleOnClose = () => {
          console.log(ranges, onChange, onSubmit, rest, formatDateDisplay);
          setShowCalendar(false);
     }

     const handleClickOutside = (event: MouseEvent) => {
          if (dateRef.current && !dateRef.current.contains(event.target as Node)) {
               setShowCalendar(false);
          }
     };

     useEffect(() => {
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
               document.removeEventListener('mousedown', handleClickOutside);
          };
     }, []);

     useEffect(() => {
          const updateRanges = async () => {
               const assetCreationDateFromLocalStorage = sessionStorage.getItem('assetCreationDate') || "";

               const assetCreationDate = selectedAssetDetails?.creationDateTime
                    ? new Date(selectedAssetDetails.creationDateTime)
                    : (assetCreationDateFromLocalStorage && new Date(assetCreationDateFromLocalStorage))
                    || subYears(new Date(), 2);
               const date1 = new Date(customMinDate);
               const date2 = new Date(assetCreationDate);
               const differenceInTime = date1?.getTime() - date2?.getTime();
               const differenceInDays = differenceInTime / (1000 * 3600 * 24);
               if (assetCreationDate) {
                    setUpdatedRanges((prev: any[]) => prev.map((range: { label: string; }) => {
                         if (range.label === 'All Time') {
                              if (differenceInDays > 0) {
                                   setCustomMinDate(date2);
                              }
                              return {
                                   ...range,
                                   range: () => ({
                                        startDate: assetCreationDate,
                                        endDate: endOfDay(new Date())
                                   })
                              };
                         }
                         return range;
                    }));
               }
          };

          updateRanges();
     }, [selectedAssetDetails]);

     return (
          <React.Fragment>
               <div className="date-picker__container" ref={dateRef} >
                    <DateRangePicker
                         onChange={handleSelect}
                         moveRangeOnFirstSelection={false}
                         months={2}
                         ranges={[calendar]}
                         direction="horizontal"
                         staticRanges={updatedRanges}
                         minDate={customMinDate}
                         maxDate={maxDate}
                    />
                    <div className="text-right position-relative rdr-buttons-position d-flex">
                         <div className='date-picker__footer-container'>
                              <div className='date-picker__footer-left-container'></div>
                              <div className='date-picker__footer-rigth-container'>
                                   <div className="date-picker__input-container">
                                        <input value={formateDate(calendar.startDate)} className="date-picker__input" readOnly={true} /> <span>-</span> <input value={formateDate(calendar.endDate)} className="date-picker__input" readOnly={true} />
                                   </div>
                                   <div className="date-picker__button-container" >
                                        <button
                                             className="date-picker__btn-secondary"
                                             onClick={() => handleOnClose()}
                                        >
                                             Cancel
                                        </button>
                                        <button
                                             className="date-picker__btn-primary"
                                             onClick={handleApply}
                                        >
                                             Apply
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </React.Fragment>
     );
};

// DateRangeSelector.defaultProps = {
//      ranges: defaultStaticRanges
// };

DateRangeSelector.propTypes = {
     /**
      * On Submit
      */
     onSubmit: PropTypes.func
};

export default DateRangeSelector;
