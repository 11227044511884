// DocumentationSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getDocuments, createDocumentData, downloadAssetDocument, deleteAssetDocument, downloadQbAssetDocumentByName } from './AssetDocumentsService';
import { AssetDocument } from './model/AssetDocument';
import { ConvertUTCToLocal } from '../../../../utilities/CommonFunctions';

interface UserState {
  loading: boolean;
  message: string | null;
  documents: AssetDocument[];
  success: boolean;
}

const initialState: UserState = {
  loading: false,
  message: null,
  success: false,
  documents: [],
};

// Define your async thunk for fetching a single user
export const fetchDocument = createAsyncThunk('document/fetchDocument', async ({ userId, wellId }: { userId: string, wellId: string }) => {
  const response = await getDocuments(userId, wellId);
  const formattedResponse = response.map((doc: AssetDocument) => {
    return {
      ...doc,
      eventDate: (doc.eventDate === null) ? null : ConvertUTCToLocal(doc.eventDate, true),
      updatedAt: (doc.updatedAt === '0001-01-01T00:00:00') ? null : ConvertUTCToLocal(new Date(doc.updatedAt), false),
    };
  });
  return formattedResponse;
});

// Define your async thunk for fetching a downloadDocument
export const downloadDocument = createAsyncThunk('document/downloadAssetDocument', async ({ id }: { id: string }) => {
  const response = await downloadAssetDocument(id);
  return response;
});

//download document for data lake source
export const downloadQbAssetDocument = createAsyncThunk('document/downloadQbAssetDocumentByName', async ({ wellId, fileName }: { wellId: string, fileName: string }) => {
  const response = await downloadQbAssetDocumentByName(wellId, fileName);
  return response;
});

// Define your async thunk for fetching a downloadDocument
export const deleteDocument = createAsyncThunk('document/deleteAssetDocument', async ({ id }: { id: string[] }) => {
  const response = await deleteAssetDocument(id);
  return response;
}
);
// Define your async thunk for fetching a single user
// Create userNotification
export const createDocument = createAsyncThunk('document/CreateAssetDocument', async (assetDocument: FormData) => {
  const response = await createDocumentData(assetDocument);
  return response;
});


const assetDocumentSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    deleteDocumentSuccess: (state, action: PayloadAction<string>) => {
      state.documents = state.documents.filter(doc => doc.id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    // Handle the fetchUserById fulfilled action
    builder.addCase(fetchDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDocument.fulfilled, (state, action) => {
      state.documents = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchDocument.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
      state.documents = [];
    });
    builder.addCase(downloadDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadDocument.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(downloadDocument.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });
    builder.addCase(deleteDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteDocument.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDocument.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });
    // Handle the createNotification fulfilled action
    builder.addCase(createDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createDocument.fulfilled, (state) => {
      //state.schedule = [];
      state.success = true;
      state.loading = false;
    });
    builder.addCase(createDocument.rejected, (state, action) => {
      state.message = action.payload as string;
      state.success = false;
      state.loading = false;
    });

    //handle download document for data lake source
    builder.addCase(downloadQbAssetDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadQbAssetDocument.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(downloadQbAssetDocument.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });
  },
});

export const { deleteDocumentSuccess } = assetDocumentSlice.actions;

export default assetDocumentSlice.reducer;
